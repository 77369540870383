let googleMapsPromise = null;
const regexCallbackFinder = /(\?|\&)callback\=.+(\&|$)/g;

module.exports = function() {
    if (googleMapsPromise) {
        return googleMapsPromise;
    }

    if (!window.CachedData.googleMapsApi || window.google) {
        googleMapsPromise = Promise.resolve();
    } else {
        // Pull cbFn
        var callbackFnName = (window.CachedData.googleMapsApi || '').match(regexCallbackFinder)?.[0].replace(/^.+\=/g,'');

        if (callbackFnName) {
            window[callbackFnName] = window[callbackFnName] || function () { console?.info('Google Maps API Callback'); };
        }

        googleMapsPromise = $.getScript(window.CachedData.googleMapsApi);
    }

    return googleMapsPromise;
}